import { RECENTLY_USED_PREFIX } from '../constants/campaignSelect';
import { USER_ATTRIBUTE_KEYS } from '../constants/userAttributes';
import { setSuperStoreValue } from '../api/superstore';
export const sanitizeValue = (value = '') => value.replace(RECENTLY_USED_PREFIX, '');
export const hasAlreadyCampaignAssociated = value => {
  return Boolean(typeof value === 'string' && value) || Array.isArray(value) && value.length > 0;
};
export function toCampaignOption({
  colorHex,
  createdAt,
  createdBy,
  display_name,
  guid,
  utm,
  objectId
}) {
  return {
    colorHex,
    createdAt,
    createdBy,
    display_name,
    guid: guid || '',
    text: display_name,
    utm,
    value: guid || '',
    objectId
  };
}
export function setCampaignToAutoSelect(campaignGuid) {
  return setSuperStoreValue(USER_ATTRIBUTE_KEYS.CAMPAIGN_TO_AUTO_SELECT, campaignGuid);
}