import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["folderId", "folderPath"],
  _excluded2 = ["folderId", "folderPath", "fileName"];
import http from 'hub-http/clients/apiClient';
import noAuthHttp from 'hub-http/clients/noAuthApiClient';
import enviro from 'enviro';
import { FileAccessValues } from '../constants/internal/fileAccess';
import { LARGE_FILE_PART_UPLOAD_MAX_RETRIES, LARGE_FILE_UPLOAD_PART_SIZE, LARGE_FILE_UPLOAD_SIZE_THRESHOLD } from '../constants';
import { hasPickerLargeFileUploadsGate } from '../utils/auth';
import { splitFileIntoChunks } from '../utils/file';
import { retryPromise } from '../utils/retryPromise';
const BASE_V3_URI = 'filemanager/api/v3/files';
const sanitizeFileName = fileName => fileName.replace(/\./gm, '-');
const COMMON_FILE_UPLOAD_PARAMS = {
  timeout: 0,
  headers: {
    'content-type': false
  }
};
const initiateMultipartUpload = fileSize => http.post(`${BASE_V3_URI}/multipart-upload/create`, {
  data: {
    contentLength: fileSize,
    partSize: LARGE_FILE_UPLOAD_PART_SIZE
  }
});
const generateMultipartUrls = (contentLength, key, uploadId) => http.post(`${BASE_V3_URI}/multipart-upload/generate-urls`, {
  data: {
    contentLength,
    key,
    partSize: LARGE_FILE_UPLOAD_PART_SIZE,
    uploadId
  }
});
const uploadFilePart = (preSignedUrl, fileChunk, progressHandler) => noAuthHttp.putWithResponse(preSignedUrl, {
  data: fileChunk,
  query: {
    // removing these default params added by http client
    hs_static_app_version: undefined,
    hs_static_app: undefined
  },
  headers: {
    // removing the default content-type header added by http client
    'content-type': false
  },
  withXhr: xhr => {
    xhr.upload.addEventListener('progress', progressHandler, false);
  }
});
const completeMultiPartUpload = (fileSize, fileName, uploadId, filePartETags, multipartUploadKeyName, fileUploadOptions) => {
  const {
      folderId,
      folderPath
    } = fileUploadOptions,
    extraOptions = _objectWithoutPropertiesLoose(fileUploadOptions, _excluded);
  return http.post(`${BASE_V3_URI}/multipart-upload/complete`, {
    // This endpoint can take a long time to complete, so we explicitly specify a timeout of 0
    // timeout of 0 means the request will not be automatically terminated by the http client
    timeout: 0,
    data: Object.assign({}, folderId && {
      folderId
    }, folderPath && {
      folderPath
    }, {
      size: fileSize,
      fileName,
      filePartETags,
      uploadId,
      key: multipartUploadKeyName,
      options: extraOptions
    })
  }).then(file => ({
    objects: [file]
  }));
};
export const uploadFileWithMultipart = async (file, progressCallback) => {
  const fileSize = file.size;
  const {
    key,
    uploadId
  } = await initiateMultipartUpload(fileSize);
  const multipartUrls = await generateMultipartUrls(fileSize, key, uploadId);
  const fileChunks = splitFileIntoChunks(file, LARGE_FILE_UPLOAD_PART_SIZE);
  const filePartETags = [];
  const fileUploadProgressByteMap = {};
  const handleChunkUploadProgress = (e, chunkNumber) => {
    const chunkProgressBytes = e.loaded;
    fileUploadProgressByteMap[chunkNumber] = chunkProgressBytes;
    const overallProgressBytes = Object.values(fileUploadProgressByteMap).reduce((acc, curr) => acc + curr, 0);
    progressCallback(overallProgressBytes);
  };
  for (let i = 0; i < multipartUrls.length; i++) {
    const {
      preSignedUrl,
      partNumber
    } = multipartUrls[i];
    const chunkUploadResponse = await retryPromise(() => uploadFilePart(preSignedUrl, fileChunks[i], e => handleChunkUploadProgress(e, partNumber)), LARGE_FILE_PART_UPLOAD_MAX_RETRIES);
    const eTag = chunkUploadResponse === null || chunkUploadResponse === void 0 ? void 0 : chunkUploadResponse.headers.etag;
    if (eTag) {
      filePartETags.push({
        partNumber,
        eTag,
        uploadId
      });
    } else {
      throw new Error('File upload failed');
    }
  }
  if (filePartETags.length !== fileChunks.length) {
    throw new Error('File upload failed');
  }
  return {
    filePartETags,
    key,
    uploadId
  };
};
export function uploadFile(file, uploadedFileAccess, fileUploadOptions = {}, {
  updateProgress,
  xhrCallback,
  httpClient = http
} = {}) {
  if (!file) {
    throw new Error('`file` arg is required');
  }
  if (!(file instanceof File || file instanceof Blob)) {
    console.warn('`file` arg should be a local File or Blob');
  }
  if (!Object.keys(FileAccessValues).includes(uploadedFileAccess)) {
    throw new Error('`uploadedFileAccess` prop is required. Visit https://product.hubteam.com/docs/file-manager-manual/Frontend/index.html for details.');
  }
  const {
      folderId,
      folderPath,
      fileName
    } = fileUploadOptions,
    extra = _objectWithoutPropertiesLoose(fileUploadOptions, _excluded2);
  const extraOptions = Object.assign({}, extra, {
    access: uploadedFileAccess
  });
  const shouldUseMultipartUpload = enviro.deployed() && file.size > LARGE_FILE_UPLOAD_SIZE_THRESHOLD && hasPickerLargeFileUploadsGate();
  let preNormalizedFileName;
  if (fileName) {
    preNormalizedFileName = fileName;
  } else if ('name' in file && file.name) {
    preNormalizedFileName = file.name;
  }
  const upload = () => {
    const formData = new FormData();
    if (preNormalizedFileName && !fileName) {
      const normalizedFileName = preNormalizedFileName.normalize('NFKC');
      formData.append('file', file, normalizedFileName);
    } else {
      formData.append('file', file);
    }
    if (folderId) {
      formData.append('folderId', folderId.toString());
    }
    if (folderPath) {
      formData.append('folderPath', folderPath);
    }
    if (fileName) {
      formData.append('fileName', fileUploadOptions.preserveExtension ? fileName : sanitizeFileName(fileName));
    }
    formData.append('options', JSON.stringify(extraOptions));
    return httpClient.post(`filemanager/api/v3/files/upload`, Object.assign({}, COMMON_FILE_UPLOAD_PARAMS, {
      data: formData,
      withXhr: xhr => {
        xhr.upload.addEventListener('progress', updateProgress, false);
        if (xhrCallback) {
          xhrCallback(xhr);
        }
      }
    }));
  };
  const multipartUpload = async () => {
    const handleUploadProgress = uploadedBytes => {
      updateProgress === null || updateProgress === void 0 || updateProgress(new ProgressEvent('progress', {
        lengthComputable: true,
        loaded: uploadedBytes,
        total: file.size
      }));
    };
    const {
      filePartETags,
      key,
      uploadId
    } = await uploadFileWithMultipart(file, handleUploadProgress);
    let normalizedFileName;
    if (preNormalizedFileName && !fileUploadOptions.fileName) {
      normalizedFileName = preNormalizedFileName.normalize('NFKC');
    } else if ('name' in file && file.name) {
      normalizedFileName = file.name;
    } else {
      normalizedFileName = 'uploaded-file';
    }
    return completeMultiPartUpload(file.size, normalizedFileName, uploadId, filePartETags, key, Object.assign({}, fileUploadOptions, {
      access: uploadedFileAccess
    }));
  };
  return shouldUseMultipartUpload ? multipartUpload() : upload();
}