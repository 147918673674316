import { getOwnedProducts } from 'self-service-api/api/getProducts';
import { HAS_FREE_OVERRIDE, HAS_PAID_OVERRIDE } from 'ui-addon-upgrades/_core/utils/commMethodOverrides';
export const getIsFreePortal = () => {
  if (HAS_FREE_OVERRIDE) {
    return Promise.resolve(true);
  }
  if (HAS_PAID_OVERRIDE) {
    return Promise.resolve(false);
  }
  return getOwnedProducts().then(productsOwned => {
    if (!productsOwned || !Array.isArray(productsOwned)) {
      return true;
    } else {
      const recurringPaidProducts = productsOwned.filter(productOwned => !productOwned.productApiName.includes('onboarding'));
      return recurringPaidProducts.length === 0;
    }
  }).catch(() => {
    return true;
  });
};
export const getIsFreePortalAndOwnedProducts = () => {
  if (HAS_FREE_OVERRIDE) {
    return Promise.resolve({
      isFreePortal: true,
      ownedProducts: []
    });
  }
  if (HAS_PAID_OVERRIDE) {
    return Promise.resolve({
      isFreePortal: false,
      ownedProducts: []
    });
  }
  return getOwnedProducts().then(productsOwned => {
    if (!productsOwned || !Array.isArray(productsOwned)) {
      return {
        isFreePortal: true,
        ownedProducts: productsOwned
      };
    } else {
      const recurringPaidProducts = productsOwned.filter(productOwned => !productOwned.productApiName.includes('onboarding'));
      return {
        isFreePortal: recurringPaidProducts.length === 0,
        ownedProducts: productsOwned
      };
    }
  }).catch(() => {
    return {
      isFreePortal: true,
      ownedProducts: []
    };
  });
};