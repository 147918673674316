import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { filterFolders, getParentFolder, getAncestors } from 'FileManagerCore/utils/FoldersAndFiles';
import { getFiles, getIsSearching, getIsFilteringArchived, getIsFilteringExtension, getIsFilteringType } from 'FileManagerCore/selectors/Files';
import { findFolderByPath, getFolders, getFoldersById } from 'FileManagerCore/selectors/Folders';
import { getUploadingFiles } from 'FileManagerCore/selectors/UploadingFiles';
import { getSearchQueryFromUrl, getQueryParams } from './Query';
import { FOLDER_PATH_QUERY_PARAM_NAME, parseFileIdFromUrlParams } from '../utils/urls';
import { parseFolderIdFromLocation, singularizeQueryParam } from '../utils/location';
import { addLeadingSlashToPath } from 'FileManagerCore/utils/stringUtils';
import { assetHasUserTeam } from 'FileManagerCore/utils/teams';
import { getIsUserSuperAdmin, getCurrentUserTeams, getUserId } from 'FileManagerCore/selectors/Auth';
export const getFolderPathFromUrl = createSelector([getQueryParams], query => {
  const folderPath = query[FOLDER_PATH_QUERY_PARAM_NAME];
  if (folderPath) {
    const singularizedFolderPath = singularizeQueryParam(folderPath);
    if (singularizedFolderPath !== '/') {
      return addLeadingSlashToPath(singularizedFolderPath);
    }
    return null;
  }
  return null;
});
export const getFolderIdFromUrl = (__state, {
  location
} = {}) => parseFolderIdFromLocation(location);
export const getFileDetailsIdFromUrl = (__state, {
  location
} = {}) => parseFileIdFromUrlParams(location);
export const getIsFilteringFiles = createSelector([getIsFilteringArchived, getIsFilteringType], (filteringArchived, filteringType) => filteringArchived || filteringType);
export const getIsSearchingOrFiltering = createSelector([getIsSearching, getIsFilteringFiles, getIsFilteringExtension], (isSearching, isFiltering, isFilteringExtension) => isSearching || isFiltering || isFilteringExtension);
const filterFilesToCurrentFolder = (files, folders, currentFolderId, folderPath) => {
  if (currentFolderId) {
    return files.filter(f => f.get('folder_id') === currentFolderId);
  }
  if (folderPath) {
    const parentFolder = folders.find(folder => folder.get('full_path') === addLeadingSlashToPath(folderPath));
    if (parentFolder) {
      return files.filter(f => {
        return f.get('folder_id') === parentFolder.get('id');
      });
    }
  }
  return files;
};
export const getFilteredFolders = createSelector([getFoldersById, getFolderPathFromUrl, getSearchQueryFromUrl, getFolderIdFromUrl, getIsFilteringFiles, getCurrentUserTeams, getIsUserSuperAdmin], (folders, folderPath, searchQuery, folderId, isFilteringFiles) => {
  if (isFilteringFiles) {
    return Immutable.List();
  }
  return filterFolders(folders, folderPath, searchQuery, folderId).toList();
});
export const getFilteredFiles = createSelector([getFiles, getFolders, getFolderIdFromUrl, getFolderPathFromUrl, getIsSearchingOrFiltering], (files, folders, folderId, folderPath, isSearchingOrFiltering) => {
  if (isSearchingOrFiltering) {
    return files;
  }
  return filterFilesToCurrentFolder(files, folders, folderId, folderPath);
});
export const getFilteredUploadingFiles = createSelector([getUploadingFiles, getFolders, getFolderIdFromUrl, getFolderPathFromUrl, getSearchQueryFromUrl], (uploadingFiles, folders, folderId, folderPath, isSearchingOrFiltering) => {
  if (isSearchingOrFiltering) {
    return uploadingFiles;
  }
  return filterFilesToCurrentFolder(uploadingFiles, folders, folderId, folderPath);
});
export const getParentFolderForObject = createSelector([(__state, props) => props.object, getFolders], getParentFolder);
export const getCurrentFolder = createSelector([getFoldersById, getFolderPathFromUrl, getFolderIdFromUrl], (foldersById, folderPath, folderId) => {
  if (!(folderPath || folderId)) {
    return null;
  }
  if (folderId) {
    return foldersById.get(folderId);
  }
  return findFolderByPath(foldersById, folderPath ? addLeadingSlashToPath(folderPath) : '');
});
export const getCurrentFolderAncestors = createSelector([getCurrentFolder, getFoldersById], getAncestors);
export const getHasAnyFolders = createSelector([getFoldersById], folders => !folders.isEmpty());
export const getHasAccessToFolder = createSelector([(state, props) => getCurrentFolder(state, props), getIsUserSuperAdmin, getUserId, getCurrentUserTeams], (currentFolder, isUserSuperAdmin, currentUserId, currentUserTeams) => {
  var _currentFolder$get$to, _currentFolder$get, _currentFolder$get$to2, _currentFolder$get2;
  if (isUserSuperAdmin) {
    return true;
  }
  const folderTeams = (_currentFolder$get$to = currentFolder === null || currentFolder === void 0 || (_currentFolder$get = currentFolder.get('teams')) === null || _currentFolder$get === void 0 ? void 0 : _currentFolder$get.toArray()) !== null && _currentFolder$get$to !== void 0 ? _currentFolder$get$to : [];
  const folderOwners = (_currentFolder$get$to2 = currentFolder === null || currentFolder === void 0 || (_currentFolder$get2 = currentFolder.get('owners')) === null || _currentFolder$get2 === void 0 ? void 0 : _currentFolder$get2.toArray()) !== null && _currentFolder$get$to2 !== void 0 ? _currentFolder$get$to2 : [];

  // if both are empty, then we are scoped to everyone
  if (folderTeams.length === 0 && folderOwners.length === 0) {
    return true;
  }

  // if at least one is not empty, then we are scoped to the provided teams or owners
  return folderOwners.includes(currentUserId) || assetHasUserTeam(folderTeams, currentUserTeams.map(team => team.id));
});