import { createSelector } from 'reselect';
import { PICKER_CHANGE_VISIBILITY_SOURCE } from 'FileManagerCore/Constants';
import getQueryFromLocation from '../utils/getQueryFromLocation';
import { SEARCH_QUERY_PARAM_NAME } from '../utils/urls';
import { parseQueryObject, singularizeQueryParam } from '../utils/location';
const getLocation = (__state, props) => {
  return props && props.location;
};
export const getQueryParams = createSelector([getLocation], parseQueryObject);
export const getQueryWithDefaults = createSelector([getLocation], getQueryFromLocation);
export const getSearchQueryFromUrl = createSelector([getQueryParams], queryObj => {
  const searchQuery = singularizeQueryParam(queryObj[SEARCH_QUERY_PARAM_NAME]);
  return searchQuery;
});
export const getSourceParam = createSelector([getQueryParams], query => query.source);
export const getIsFromPickerChangeVisibility = createSelector([getSourceParam], source => source === PICKER_CHANGE_VISIBILITY_SOURCE);