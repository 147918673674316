import { useEffect, useState } from 'react';
import http from 'hub-http/clients/apiClient';
import { CAMPAIGN_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getUnformattedCrmSearchCampaign } from 'campaigns-lib/data/CampaignDao';
import { campaignFields } from '../constants/campaignFieldsForEditing';
const CAMPAIGN_PROPERTY_ALLOWLIST = ['hs_name', 'hs_color_hex', 'hs_all_assigned_business_unit_ids', 'hs_owner', 'hs_goal'];
const CAMPAIGN_OBJECT_ALLOWLIST = {
  display_name: 'hs_name',
  owner: 'hs_owner',
  goal: 'hs_goal'
};
function getProperties() {
  return http.get(`/properties/v4/${CAMPAIGN_TYPE_ID}`);
}
export function formatDefaultProperties({
  crmSearchResponse,
  campaignProperties
}) {
  if (!campaignProperties) {
    return {};
  }
  const campaignCrmObject = crmSearchResponse && crmSearchResponse.results && crmSearchResponse.results.length ? crmSearchResponse.results[0] : undefined;
  if (!campaignCrmObject) {
    return {};
  }

  // The properties that can be edited and therefore could be included in the edit form
  const editableProperties = campaignProperties && campaignProperties.filter(property => {
    const isReadOnly = property.property.readOnlyValue;
    const isHidden = property.property.hidden;
    const isInAllowlist = CAMPAIGN_PROPERTY_ALLOWLIST.includes(property.property.name);
    return !isReadOnly && !isHidden || isInAllowlist;
  }).map(property => property.property.name);

  // The default properties to display in the custom edit panel
  return Object.keys(campaignCrmObject.properties).reduce((acc, propKey) => {
    if (editableProperties && editableProperties.includes(propKey)) {
      acc[propKey] = campaignCrmObject.properties[propKey].value;
    }
    return acc;
  }, {});
}
export default function useDefaultProperties({
  campaign
} = {}) {
  const [state, setState] = useState({
    data: undefined,
    error: false,
    loading: false
  });

  /**
   * Find campaign by guid and set default properties
   */
  useEffect(() => {
    if (!campaign || !campaign.guid) {
      return;
    }
    setState({
      data: undefined,
      error: false,
      loading: true
    });
    Promise.all([getUnformattedCrmSearchCampaign(campaign.guid, {
      allPropertiesFetchMode: 'latest_version',
      includeAllProperties: true
    }), getProperties()]).then(([crmSearchResponse, campaignProperties]) => {
      const defaultProperties = formatDefaultProperties({
        crmSearchResponse,
        campaignProperties
      });
      setState({
        data: Object.assign({}, defaultProperties, campaign ? {
          hs_name: campaign[campaignFields.DISPLAY_NAME],
          hs_template_guid: campaign.templateGuid,
          hs_owner: campaign.owner,
          hs_goal: campaign.goal
        } : {}),
        error: false,
        loading: false
      });
    }).catch(() => {});
  }, [campaign]);

  /**
   * Define initial value for all properties defined in CAMPAIGN_OBJECT_ALLOWLIST
   */
  useEffect(() => {
    if (!campaign || campaign.guid) {
      return;
    }
    const campaignCrmObject = Object.keys(campaign).reduce((acc, key) => {
      if (key in CAMPAIGN_OBJECT_ALLOWLIST) {
        const crmPropertyName = CAMPAIGN_OBJECT_ALLOWLIST[key];
        acc.properties[crmPropertyName] = {
          value: campaign[key]
        };
      }
      return acc;
    }, {
      associatedObjects: {},
      properties: {}
    });
    if (Object.keys(campaignCrmObject.properties).length === 0) {
      return;
    }
    setState({
      data: undefined,
      error: false,
      loading: true
    });
    getProperties().then(campaignProperties => {
      const crmSearchResponse = {
        results: [campaignCrmObject]
      };
      const defaultProperties = formatDefaultProperties({
        crmSearchResponse,
        campaignProperties
      });
      setState({
        data: defaultProperties,
        error: false,
        loading: false
      });
    }).catch(() => {});
  }, [campaign]);
  return state;
}