module.exports = {
  "clickChangeThumbnail": {
    "name": "edit details",
    "class": "interaction",
    "namespace": "video-components",
    "version": "1"
  },
  "updateThumbnailFromTimestamp": {
    "name": "edit details",
    "class": "interaction",
    "namespace": "video-components",
    "version": "1"
  },
  "replaceThumbnail": {
    "name": "edit details",
    "class": "interaction",
    "namespace": "video-components",
    "version": "1"
  },
  "uploadThumbnail": {
    "name": "edit details",
    "class": "interaction",
    "namespace": "video-components",
    "version": "1"
  },
  "enableAdvancedFeatures": {
    "name": "edit details",
    "class": "interaction",
    "namespace": "video-components",
    "version": "1"
  },
  "disableAdvancedFeatures": {
    "name": "edit details",
    "class": "interaction",
    "namespace": "video-components",
    "version": "1"
  }
};