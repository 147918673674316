export const AD_CAMPAIGN = 'AD_CAMPAIGN';
export const APPOINTMENT = 'APPOINTMENT';
export const ATTRIBUTION = 'ATTRIBUTION';
export const AUTOMATION_PLATFORM_FLOW = 'AUTOMATION_PLATFORM_FLOW';
export const BLOG_LISTING_PAGE = 'BLOG_LISTING_PAGE';
export const BLOG_POST = 'BLOG_POST';
export const CAMPAIGN = 'CAMPAIGN';
export const CART = 'CART';
export const COMMUNICATION = 'COMMUNICATION';
export const COMPANY = 'COMPANY';
export const CONTACT = 'CONTACT';
export const CONTACT_CREATE_ATTRIBUTION = 'CONTACT_CREATE_ATTRIBUTION';
export const CONVERSATION = 'CONVERSATION';
export const COURSE = 'COURSE';
export const CTA = 'CTA';
export const DATA_PRIVACY_REQUEST = 'DATA_PRIVACY_REQUEST';
export const DEAL = 'DEAL';
export const DISCOUNT = 'DISCOUNT';
export const DISCOUNT_TEMPLATE = 'DISCOUNT_TEMPLATE';
export const ENGAGEMENT = 'ENGAGEMENT';
export const FEE = 'FEE';
export const FEEDBACK_SUBMISSION = 'FEEDBACK_SUBMISSION';
export const FEEDBACK_SURVEY = 'FEEDBACK_SURVEY';
export const FOLDER = 'FOLDER';
export const FORM = 'FORM';
export const FORM_DASHBOARD = 'FORM_DASHBOARD';
export const GOAL_TARGET = 'GOAL_TARGET';
export const KNOWLEDGE_ARTICLE = 'KNOWLEDGE_ARTICLE';
export const LANDING_PAGE = 'LANDING_PAGE';
export const LEAD = 'LEAD';
export const LINE_ITEM = 'LINE_ITEM';
export const LISTING = 'LISTING';
export const MARKETING_EMAIL = 'MARKETING_EMAIL';
export const MARKETING_EVENT = 'MARKETING_EVENT';
export const MARKETING_SMS = 'MARKETING_SMS';
export const MEETING_EVENT = 'MEETING_EVENT';
export const OBJECT_LIST = 'OBJECT_LIST';
export const ORDER = 'ORDER';
export const PARTNER_ACCOUNT = 'PARTNER_ACCOUNT';
export const PARTNER_CLIENT = 'PARTNER_CLIENT';
export const PARTNER_SERVICE = 'PARTNER_SERVICE';
export const DATA_PRIVACY_CONSENT = 'DATA_PRIVACY_CONSENT';
export const PRODUCT = 'PRODUCT';
export const QUOTE = 'QUOTE';
export const QUOTE_MODULE = 'QUOTE_MODULE';
export const QUOTE_MODULE_FIELD = 'QUOTE_MODULE_FIELD';
export const QUOTE_TEMPLATE = 'QUOTE_TEMPLATE';
export const SALES_DOCUMENT = 'SALES_DOCUMENT';
export const SEQUENCE_ENROLLMENT = 'SEQUENCE_ENROLLMENT';
export const SERVICE = 'SERVICE';
export const SITE_PAGE = 'SITE_PAGE';
export const SOCIAL_BROADCAST = 'SOCIAL_BROADCAST';
export const TASK = 'TASK';
export const TAX = 'TAX';
export const TICKET = 'TICKET';
export const VISIT = 'VISIT';
export const USER = 'USER';
export const WEB_INTERACTIVE = 'WEB_INTERACTIVE';
export const NOTE = 'NOTE';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error module/module.exports aren't currently supported
if (!!module && !!module.exports) {
  // @ts-expect-error module/module.exports aren't currently supported
  module.exports.default = module.exports;
}