import Hubs from './Hubs';
const HubTypes = {
  MARKETING: 'MARKETING',
  SALES: 'SALES',
  SERVICE: 'SERVICE',
  CMS: 'CONTENT',
  OPERATIONS: 'OPERATIONS'
};
export const HubTypeToHubMap = {
  [HubTypes.MARKETING]: Hubs.HUB_MARKETING,
  [HubTypes.SALES]: Hubs.HUB_SALES,
  [HubTypes.SERVICE]: Hubs.HUB_SERVICE,
  [HubTypes.CMS]: Hubs.HUB_CMS,
  [HubTypes.OPERATIONS]: Hubs.HUB_OPERATIONS
};
export default HubTypes;