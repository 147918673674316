export const assetHasUserTeam = (assetTeams, userTeams) => {
  if (!assetTeams) {
    return false;
  }
  return assetTeams.some(assetTeam => userTeams.includes(assetTeam));
};
export const flattenTeams = (team, path = [], result = []) => {
  if (!team.childTeams.length) {
    result.push(path.concat(team));
  }
  for (const child of team.childTeams) {
    flattenTeams(child, path.concat(team), result);
  }
  return result;
};