import { gate } from 'hub-http/gates';
import { userInfoSync } from 'hub-http/userInfo';
const FILE_MANAGER_WRITE_SCOPE = 'file-manager-write';
const PICKER_LARGE_FILE_UPLOADS_GATE = gate('FilePicker:LargeFileUploads');
export const IMAGE_GEN_NEGATIVE_PROMPT = gate('FileManagerUI:imageGenNegativePrompts');
export const hasScope = scopeName => {
  try {
    const auth = userInfoSync();
    return auth.user.scopes.includes(scopeName);
  } catch (err) {
    return false;
  }
};
export const hasGate = gateName => {
  try {
    const {
      gates
    } = userInfoSync();
    return gates.includes(gateName);
  } catch (err) {
    return false;
  }
};
export const hasPickerLargeFileUploadsGate = () => hasGate(PICKER_LARGE_FILE_UPLOADS_GATE);
export const hasFilesWriteScope = () => hasScope(FILE_MANAGER_WRITE_SCOPE);
export const getDefaultLanguage = () => {
  try {
    const {
      user
    } = userInfoSync();
    return user.lang_enabled ? user.locale : 'en';
  } catch (err) {
    console.error(err);
    return 'en';
  }
};