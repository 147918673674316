import { createSelector } from 'reselect';
import { getFilteredUploadingFiles } from './FoldersAndFiles';
import { getQueryWithDefaults } from './Query';
import { getPageSize } from './pageSize';
export const getFilesAndFoldersTotal = state => state.filesAndFoldersTable.get('total');
export const getFetchFilesAndFoldersRequestStatus = state => state.filesAndFoldersTable.get('status');
export const getIsFetchingMoreFilesAndFolders = state => !!state.filesAndFoldersTable.get('fetchingMore');
const getObjects = state => state.filesAndFoldersTable.get('objects');
export const getTableFoldersAndFiles = createSelector([getObjects, getFilteredUploadingFiles, getFilesAndFoldersTotal], (fetchedObjects, uploadingFiles, foldersAndFilesTotal) => {
  const total = foldersAndFilesTotal + uploadingFiles.size;
  const objects = uploadingFiles.toList().concat(fetchedObjects);
  return {
    total,
    objects
  };
});
export const getCurrentPageOfFoldersAndFiles = createSelector([getTableFoldersAndFiles, getQueryWithDefaults, getPageSize], (allFoldersAndFiles, query, pageSize) => {
  const page = query.page || 1;
  return {
    total: allFoldersAndFiles.total,
    objects: allFoldersAndFiles.objects.slice((page - 1) * pageSize, page * pageSize)
  };
});