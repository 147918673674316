import { FileTypes } from 'FileManagerCore/Constants';
import { QueryParams } from '../Constants';
import { parseSearchString } from 'FileManagerCore/utils/urls';
export function parseQueryObject(location) {
  if (location && location.search) {
    return parseSearchString(location.search);
  }
  return {};
}
export function singularizeQueryParam(queryParam) {
  return Array.isArray(queryParam) ? queryParam[0] : queryParam;
}
export function parseFileTypeFromLocation(location) {
  const fileType = singularizeQueryParam(parseQueryObject(location).fileType);
  if (fileType && Object.keys(FileTypes).includes(fileType.toUpperCase())) {
    return fileType.toUpperCase();
  }
  return null;
}
export function parseFolderIdFromLocation(location) {
  if (!location) {
    return null;
  }
  const queryParams = parseQueryObject(location);
  const folderIdParam = String(queryParams[QueryParams.folderId]);
  if (!folderIdParam) {
    return null;
  }
  const folderId = parseInt(folderIdParam, 10);
  return folderId > 0 ? folderId : null;
}