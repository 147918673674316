import events from '../../events.yaml';
const NOOP_TRACKER = {
  track: () => {}
};
const makeUsageTrackerContainer = () => {
  let usageTracker;
  const setLibraryTracker = tracker => {
    usageTracker = tracker;
  };
  const initialize = tracker => {
    const libraryTracker = tracker.clone({
      preserveTrackerProperties: false,
      trackerName: 'video-components',
      events
    });
    setLibraryTracker(libraryTracker);
  };
  const getLibraryTracker = () => {
    return usageTracker || NOOP_TRACKER;
  };
  return {
    setLibraryTracker,
    initialize,
    getLibraryTracker
  };
};
export const usageTrackerContainer = makeUsageTrackerContainer();