import { CANVA_INTEGRATION_SCOPE, MARKETING_VIDEO_SCOPE, FILE_MANAGER_ACCESS, FILE_MANAGER_DASH_SCOPE, FILE_MANAGER_WRITE_SCOPE, FILE_HOSTING_PAID_DOMAINS_SCOPE, FREE_FILE_HOSTING_DOMAIN_GATE, PICKER_FIREALARM_GATE, SUPER_ADMIN_SCOPE, FILE_MANAGER_DELETE_SCOPE, TEAMS_ACCESS_SCOPE, IMAGE_GENERATION_SCOPE, FILE_MANAGER_VIEW_SCOPE, LARGE_FILE_UPLOADS_GATE, AI_POWERED_SEARCH_GATE, PICKER_LAYOUT_CHANGES_GATE, IMAGE_GEN_V2_GATE, VIDEO_SUBTITLE_GENERATION, PICKER_LARGE_FILE_UPLOADS_GATE, SOURCE_GROUPS_GATE, VIDEO_DETAILS_REFRESH_GATE, VIDEO_ADVANCED_FEATURES_ON_DEMAND_GATE, FILE_DEDUPLICATION_GATE, PLG_ZERO_STATE_GATE } from '../Constants';
const isUngated = (state, gateName) => state.auth.gates.includes(gateName);
const hasScope = (state, scopeName) => state.auth.user.scopes.includes(scopeName);
export const getLocale = state => state.auth.user.locale;
export const getHasCanvaIntegrationScope = state => state.auth.user.scopes.indexOf(CANVA_INTEGRATION_SCOPE) > -1;
export const getHasVideoIntegrationScope = state => state.auth.user.scopes.indexOf(MARKETING_VIDEO_SCOPE) > -1;
export const getHasFileManagerAccessScope = state => state.auth.user.scopes.indexOf(FILE_MANAGER_ACCESS) > -1;
export const getHasFileManagerWriteScope = state => hasScope(state, FILE_MANAGER_WRITE_SCOPE);
export const getHasFileHostingPaidDomainsScope = state => hasScope(state, FILE_HOSTING_PAID_DOMAINS_SCOPE);
export const getHasTeamsAccessScope = state => hasScope(state, TEAMS_ACCESS_SCOPE);
export const getUserEmailAddress = state => state.auth.user.email;
export const getUser = state => state.auth.user;
export const getUserId = state => state.auth.user.user_id;
export const getCurrentUserTeams = state => state.auth.user.teams;
export const getHasCustomFileHostingDomainAccess = state => isUngated(state, FREE_FILE_HOSTING_DOMAIN_GATE) ? getHasFileHostingPaidDomainsScope(state) : true;
export const getIsUngatedForPickerFireAlarm = state => isUngated(state, PICKER_FIREALARM_GATE);
export const getIsUserSuperAdmin = state => hasScope(state, SUPER_ADMIN_SCOPE);
export const getHasFilesNavItem = state => hasScope(state, FILE_MANAGER_DASH_SCOPE);
export const selectIsUngatedForImageGenV2 = state => isUngated(state, IMAGE_GEN_V2_GATE);
export const selectUserHasFileManagerDeleteScope = state => hasScope(state, FILE_MANAGER_DELETE_SCOPE);
export const selectHasImageGenerationScope = state => hasScope(state, IMAGE_GENERATION_SCOPE);
export const selectUserHasFileManagerViewScope = state => hasScope(state, FILE_MANAGER_VIEW_SCOPE);
export const selectIsUngatedForLargeFileUploads = state => isUngated(state, LARGE_FILE_UPLOADS_GATE);
export const selectIsUngatedForPickerLayoutChanges = state => isUngated(state, PICKER_LAYOUT_CHANGES_GATE);
export const selectIsUngatedForAiPoweredSearch = state => isUngated(state, AI_POWERED_SEARCH_GATE);
export const selectIsUngatedForSubtitleGeneration = state => isUngated(state, VIDEO_SUBTITLE_GENERATION);
export const selectIsPickerUngatedForLargeFileUploads = state => isUngated(state, PICKER_LARGE_FILE_UPLOADS_GATE);
export const selectIsUngatedForSourceGroups = state => isUngated(state, SOURCE_GROUPS_GATE);
export const selectIsUngatedForVideoDetailsRefresh = state => isUngated(state, VIDEO_DETAILS_REFRESH_GATE);
export const selectIsUngatedForVideoAdvancedFeaturesOnDemand = state => isUngated(state, VIDEO_ADVANCED_FEATURES_ON_DEMAND_GATE);
export const selectIsUngatedForFileDeduplication = state => isUngated(state, FILE_DEDUPLICATION_GATE);
export const selectIsUngatedForPlgZeroState = state => isUngated(state, PLG_ZERO_STATE_GATE);