import { useCallback, useRef } from 'react';
import { callIfPossible } from 'UIComponents/core/Functions';
import I18n from 'I18n';
import { DEFAULT_CACHE_GRACE_PERIOD, DEFAULT_NUMBER_OF_RECENTLY_USED, RECENTLY_USED_PREFIX } from '../constants/campaignSelect';
import { CRM_SEARCH_SORT, SORT_DIR, PAGE_DEFAULT_LIMIT } from '../constants/campaignDaoConstants';
import { getCrmSearchCampaign, getCrmSearchCampaignsPaginatedAsOption } from '../data/CampaignDao';
import { UsageTracker } from '../utils/usageTracker';
export default function useLoadCampaignSelectOptions({
  applicationId,
  numberOfRecentlyUsed = DEFAULT_NUMBER_OF_RECENTLY_USED,
  onCurrentValueNotFound,
  pageLimit = PAGE_DEFAULT_LIMIT,
  recentlyUsedCacheGracePeriod = DEFAULT_CACHE_GRACE_PERIOD,
  value,
  createdCampaigns,
  multi,
  disabledOptionsByName,
  setShowEmptyState,
  setHasLoadedFullOptions,
  onFetchCampaignsError
}) {
  const recentlyUsedCacheRef = useRef([]);
  const recentlyUsedCacheTimestampRef = useRef(undefined);
  const paginatedCacheRef = useRef([]);
  const currentValueOptionRef = useRef(undefined);
  const findCampaignByGuid = useCallback(campaign => {
    if (multi) {
      return value && Array.isArray(value) && value.indexOf(campaign.guid) > -1;
    }
    return campaign.guid === value;
  }, [value, multi]);
  const shouldDisableCampaign = useCallback(campaignName => (disabledOptionsByName || []).some(disabledOption => disabledOption.toUpperCase() === campaignName.toUpperCase()), [disabledOptionsByName]);
  const loadOptions = useCallback((input, callback, pagination = {
    offset: 0
  }) => {
    if (pagination.offset > 0) {
      UsageTracker.track('campaignSelectInteraction', {
        action: 'load more campaigns',
        applicationId
      });
    }
    const timestamp = new Date().valueOf();
    const payload = {
      name: input,
      offset: pagination.offset,
      limit: pageLimit
    };
    const isRecentlyUsedExpired = !recentlyUsedCacheTimestampRef.current || timestamp - recentlyUsedCacheTimestampRef.current > recentlyUsedCacheGracePeriod;
    const getRecentlyUsedCrmSearchPromise = isRecentlyUsedExpired ? getCrmSearchCampaignsPaginatedAsOption({
      sort: CRM_SEARCH_SORT.UPDATED_AT,
      sortDir: SORT_DIR.DESC,
      limit: numberOfRecentlyUsed
    }) : Promise.resolve({
      hasMore: false,
      offset: 0,
      results: [],
      total: 0
    });
    Promise.all([getRecentlyUsedCrmSearchPromise, getCrmSearchCampaignsPaginatedAsOption(payload)]).then(([recentlyUsedResponse, paginatedResponse]) => {
      if (recentlyUsedResponse.results.length > 0) {
        // If we have recently used payload we cache it and the timestamp
        recentlyUsedCacheRef.current = recentlyUsedResponse.results;
        recentlyUsedCacheTimestampRef.current = timestamp;
      }

      // Add recently made campaigns from the select that might not
      // be synced to come through crm-search just yet.
      const missingCreatedCampaigns = (createdCampaigns || []).filter(campaign => {
        return !recentlyUsedCacheRef.current.find(recentCampaign => recentCampaign.guid === campaign.guid);
      });
      if (missingCreatedCampaigns.length > 0) {
        recentlyUsedCacheRef.current = missingCreatedCampaigns.concat(recentlyUsedCacheRef.current);
      }

      // We add the paginated campaigns to the cache,
      // concatenating them if we're on the next page
      // otherwise resetting the cache..
      paginatedCacheRef.current = pagination.offset > 0 ? paginatedCacheRef.current.concat(paginatedResponse.results) : paginatedResponse.results;
      const isCurrentValueListed = value && (recentlyUsedCacheRef.current.some(findCampaignByGuid) || paginatedCacheRef.current.some(findCampaignByGuid));
      if (isCurrentValueListed) {
        // if the value is listed we ensure to unset the current value option
        currentValueOptionRef.current = undefined;
      }
      const hasValue = multi && value ? value.length > 0 : value;
      if (hasValue && !isCurrentValueListed && !currentValueOptionRef.current && value) {
        // If we have a current value and it isn't listed in either the recently used
        // or in the paginated result or the current value option has not been set
        // we fetch it and save it into the cache
        return getCrmSearchCampaign(value).then(({
          results: currentValueResponse
        }) => {
          if (currentValueResponse.length === 0) {
            callIfPossible(onCurrentValueNotFound);
            return paginatedResponse;
          }

          // If we have a response for the current value fetch
          // we set it to currentValueOptionRef
          currentValueOptionRef.current = currentValueResponse.map(campaign => Object.assign({}, campaign, {
            text: campaign.display_name,
            value: campaign.guid
          }));
          return paginatedResponse;
        }).catch(() => {
          return paginatedResponse;
        });
      }
      return Promise.resolve(paginatedResponse);
    }).then(paginatedResponse => {
      const options = [];
      let length = paginatedResponse.total;
      if (currentValueOptionRef.current) {
        // if we have a current value option we add it on top
        options.push(...currentValueOptionRef.current);
        length += currentValueOptionRef.current.length;
      }
      if (recentlyUsedCacheRef.current.length > 0) {
        options.push({
          text: I18n.text('campaignSelect.recentlyUsed'),
          isRecentlyUsed: true,
          options: recentlyUsedCacheRef.current.map(campaign => {
            // if recently used were previously saved, we add them on top of the options
            const isCurrentOptionListedInPaginated = paginatedCacheRef.current.find(cachedCampaign => cachedCampaign.guid === campaign.guid);
            return Object.assign({}, campaign, {
              isRecentlyUsed: true,
              containsSearchQuery: Boolean(input),
              text: campaign.display_name,
              value: `${
              // if the the current value is not listed in the paginated results
              // and is actually the iterator campaign, we won't add a prefix
              // so that the UISelect will select it
              !isCurrentOptionListedInPaginated || multi ? '' : RECENTLY_USED_PREFIX}${campaign.guid}`,
              disabled: shouldDisableCampaign(campaign.display_name)
            });
          })
        });
        length += recentlyUsedCacheRef.current.length;
      }

      // we finally push the paginated campaigns
      options.push({
        text: I18n.text('campaignSelect.allCampaigns'),
        options: paginatedCacheRef.current.map(campaign => {
          return Object.assign({}, campaign, {
            text: campaign.display_name,
            value: campaign.guid,
            disabled: shouldDisableCampaign(campaign.display_name),
            isRecentlyUsed: false,
            containsSearchQuery: Boolean(input)
          });
        })
      });
      callback(null, {
        options,
        pagination: {
          hasMore: paginatedResponse.hasMore,
          length,
          offset: paginatedResponse.offset
        }
      });
      if (setShowEmptyState) {
        setShowEmptyState(Array.isArray(options) ? options.every(option => option.options ? option.options.length === 0 : !option) : !options);
      }
      if (setHasLoadedFullOptions) {
        setHasLoadedFullOptions(true);
      }
    }).catch(error => {
      callback(null, {
        options: [],
        pagination
      });
      if (setHasLoadedFullOptions) {
        setHasLoadedFullOptions(true);
      }
      if (onFetchCampaignsError) {
        onFetchCampaignsError(error);
      }
    });
  }, [pageLimit, recentlyUsedCacheGracePeriod, numberOfRecentlyUsed, applicationId, createdCampaigns, value, findCampaignByGuid, multi, onCurrentValueNotFound, setShowEmptyState, setHasLoadedFullOptions, shouldDisableCampaign, onFetchCampaignsError]);
  return loadOptions;
}