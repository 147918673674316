export const retryPromise = async (fn, maxRetries) => {
  let retryCount = 0;
  while (retryCount <= maxRetries) {
    try {
      return await fn();
    } catch (error) {
      if (retryCount === maxRetries) {
        throw error;
      }
      retryCount++;
      // exponential backoff
      const delayMs = Math.pow(2, retryCount) * 1000;
      await new Promise(resolve => setTimeout(resolve, delayMs));
    }
  }
  throw new Error('Unexpected error: retry loop completed without return or throw');
};